/* File: frontend/main/src/index.css */
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');
@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100;200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --background-light: linear-gradient(135deg, #6b6b6b 0%, #ebebeb 100%);
  --background-dark: linear-gradient(135deg, #1b1b1b 0%, #3d3d3d 100%);
  --background-gradient: var(--background-light);
  --text-color: #000000; 
  --header-color: #333333;
  --link-color: #0000EE; 
  --font-size-large: 20px;
  --font-size-small: 16px;
}

body {
  color: var(--text-color);
  background: var(--background-gradient);
  user-select: none;
  font-family: 'Martian Mono', monospace, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--header-color);
}

a {
  color: var(--link-color);
}